<template>
    <div class="animated fadeIn">
    <div id="snackbar"></div>       
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Info Type </strong> <small>Form</small>
          </div>

          <b-form v-on:submit.prevent.self="submit">
          <b-row>
            <b-col sm="6">
              <b-form-group>
                <label for="title">Title <span class="text-danger">*</span></label>
                <b-form-input type="text" required v-model="info.InfoTypeName" id="title" placeholder="Info Type"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <label for="type_code">Info Type Code <span class="text-danger">*</span></label>
                <b-form-input  type="number" step="any" v-model="info.TypeCode" id="type-code" placeholder="Info Type Code"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group>
                <label for="info_of">Info of <span class="text-danger">*</span></label>
                <b-form-input type="text" required v-model="info.InfoOf" id="info-of" placeholder="Info of"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <label for="description">Description</label>
                <b-form-input type="text" required v-model="info.Desc_Info_Sub_type" id="description" placeholder="Description"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- <b-col sm="6">
              <b-form-group>
                <label for="sub_type">Info Sub Type</label>
                <b-form-input type="text" required v-model="info.SubType" id="sub_type" placeholder="Info Sub Type"></b-form-input>
              </b-form-group>
            </b-col> -->
            <b-col sm="6">
              <b-form-group>
                <label for="sort_order">Sort Order <span class="text-danger">*</span></label>
                <b-form-input type="text" required v-model="info.SortOrder" id="sort-order" placeholder="Sort Order"></b-form-input>
              </b-form-group>
            </b-col>

             <b-col sm="6">
              <b-form-group>
                <label for="status">Status</label>
                <br>
                  <c-switch class="mx-1" color="primary" v-model="info.InfoTypeIsActive" defaultChecked variant="3d" label v-bind="labelIcon" />
              </b-form-group>
            </b-col>


            </b-row>
            <!-- <b-row>
           
            
          </b-row> -->

          <b-row>
              <b-col sm="12">
                  <b-button type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> {{ commontext.submitButton }}</b-button>
              </b-col>
          </b-row>
          </b-form>
        </b-card>

         <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>List of Info Types
        <div class="card-header-actions">
          
        </div>
      </b-card-header>
      <b-card-body>
        <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
          <div slot="InfoTypeIsActive" slot-scope="props">
            <c-switch class="mx-1" color="primary" v-model="props.row.InfoTypeIsActive" variant="3d" label v-bind="labelIcon" @change.native="changeStatus(props.row.InfoTypeID)"/>
          </div>
         <div slot="InfoTypeID" slot-scope="props">
            <i class="fa fa-edit" @click="editInfoType(props.row.InfoTypeID)"></i>
          </div>
        </v-client-table>
      </b-card-body>
    </b-card>

      </b-col>
    </b-row>
    <b-modal id="ModalConfirmationOnSubmit" ref="ModalConfirmationOnSubmit" @ok="handleModalOkay" :title="modalObject.title" class="center-footer" footer-class="justify-content-center border-0" cancel-variant="outline-danger" ok-variant="outline-success" centered hide-header no-close-on-esc no-close-on-backdrop >
         <div class="d-block text-center">
           <div>{{ modalObject.message }}</div>
         </div>
       </b-modal>
  </div>    
</template>
<script>
import { Switch as cSwitch } from "@coreui/vue";
import MasterService from "@/services/MasterService";
import { Event } from "vue-tables-2";
export default {
  name: "InfoType",
  components: {
    cSwitch,
    Event
  },
  data() {
    return {
      commontext: {
        submitButton: "Submit"
      },
      info: {
        InfoTypeID: null,
        InfoTypeName: "",
        Flag: 1,
        InfoOf: "",
        Desc_Info_Sub_type: "",
        SortOrder: "",
        InfoTypeIsActive: true
      },
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      columns: ["InfoTypeName", "InfoTypeIsActive"],
      data: [],
      options: {
        headings: {
          InfoTypeName: "Info Type Name",
          InfoTypeIsActive: "Status"
          // code: 'State ID',
          // uri: 'View Record'
        },
        sortable: ["InfoTypeName"],
        filterable: ["InfoTypeName"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default"
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo: function() {
      // let res = await MasterService.getInfoType();
      this.data = [];
      MasterService.getInfoType()
        .then(response => {
          const { data } = response;
          if (data.Status && data.InfoList.length > 0) {
            this.data = data.InfoList.reverse();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    validation: function() {
      if (this.info.InfoTypeName == "") {
        this.showMessageSnackbar("Info Title Cannot be empty.");
        return false;
      } else if (this.info.TypeCode == "") {
        this.showMessageSnackbar("Info Type Code Cannot be empty.");
        return false;
      } else if (this.info.InfoOf == "") {
        this.showMessageSnackbar("Info Of Cannot be empty.");
        return false;
      } else if (this.info.Desc_Info_Sub_type == "") {
        this.showMessageSnackbar("Info Description Cannot be empty.");
        return false;
      } else if (this.info.SortOrder == "") {
        this.showMessageSnackbar("Info Sort Order Cannot be empty.");
        return false;
      } else {
        return true;
      }
    },
    submit: function() {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      // let res = await MasterService.addInfoType(this.info);
      this.closeModal("ModalConfirmationOnSubmit");

      MasterService.addInfoType(this.info)
        .then(response => {
          const { data } = response;
          this.getInfo();
          data.Message ? this.showMessageSnackbar(data.Message) : "";
          if (data.Status) {
            this.info = {
              InfoTypeID: null,
              Flag: 1
            };
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editInfoType: function(InfoTypeID) {
      // let res = await getInfoTypeByID(InfoTypeID);
      // this.info = res.data[0];
      let payload = {
        InfoTypeID
      };
      MasterService.getInfoTypeByID(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.InfoList.length > 0) {
            this.info = data.InfoList[0];
            this.info.Flag = 2;
            this.commontext.submitButton = "Update Country";

            this.scrollToTop();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    changeStatus: function(ID) {
      // let res = await MasterService.activateOrDeactivate(ID, "InfoType");

      let payload = {
        TableName: "InfoType",
        ID
      };
      MasterService.activateOrDeactivate(payload)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
